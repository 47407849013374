export default function ({route, redirect}) {
    if (route.path === '/cheats/csgo') {
        redirect(301, '/cheats/cs2')
    } else if (route.path === '/en/cheats/csgo') {
        redirect(301, '/en/cheats/cs2')
    } else if (route.path === '/cheats/csgo/private_hack') {
        redirect(301, '/cheats/cs2')
    } else if (route.path === '/cheats/csgo/phoenix') {
        redirect(301, '/cheats/cs2')
    } else if (route.path === '/cheats/csgo/macros') {
        redirect(301, '/cheats/cs2')
    } else if (route.path === '/cheats/csgo/phoenix_lite') {
        redirect(301, '/cheats/cs2')
    } else if (route.path === '/en/cheats/csgo/private_hack') {
        redirect(301, '/en/cheats/cs2')
    } else if (route.path === '/en/cheats/csgo/phoenix') {
        redirect(301, '/en/cheats/cs2')
    } else if (route.path === '/en/cheats/csgo/macros') {
        redirect(301, '/en/cheats/cs2')
    } else if (route.path === '/en/cheats/csgo/phoenix_lite') {
        redirect(301, '/en/cheats/cs2')
    } else if (route.path === '/cheats/super_people') {
        redirect(301, '/')
    } else if (route.path === '/en/cheats/super_people') {
        redirect(301, '/en')
    } else if (route.path === '/cheats/super_people/super_people') {
        redirect(301, '/')
    } else if (route.path === '/en/cheats/super_people/super_people') {
        redirect(301, '/en')
    } else if (route.path === '/cheats/cycle_frontier') {
        redirect(301, '/')
    } else if (route.path === '/en/cheats/cycle_frontier') {
        redirect(301, '/en')
    } else if (route.path === '/cheats/cycle_frontier/private_hack') {
        redirect(301, '/')
    } else if (route.path === '/en/cheats/cycle_frontier/private_hack') {
        redirect(301, '/en')
    } else if (route.path === '/cheats/cycle_frontier/multihack') {
        redirect(301, '/')
    } else if (route.path === '/en/cheats/cycle_frontier/multihack') {
        redirect(301, '/en')
    }
}